* {
  letter-spacing: 0.015em;
}

.ant-layout {
  height: 100vh;
  min-width: 405px;
  min-height: 800px;
}

.ant-dropdown-trigger,
.ant-upload,
.mobile-logout {
  cursor: pointer;
}

.ReactCrop__drag-handle {
  background-color: #91caff;
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

/* Overwrite antd filter collapse style */

.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header {
  color: black;
  cursor: default;
  font-weight: 500;
  font-size: 18px;
}

/* Overwrite antd card style */

.ant-card .ant-card-cover img + .ant-image-mask {
  border-radius: 0 !important;
}

/* Overwrite antd menu item for sidebar style */

.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title,
.ant-menu-vertical .ant-menu-submenu-title {
  padding-inline: 0 !important;
}


